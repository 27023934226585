// Libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Breadcrumbs,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";

// Custom components
import AlertDialog from "../components/AlertDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import { SuccessCancelOrderModal } from "../components/Orders/SuccesCancelOrderModal";

// API
import {
  getOrderInvoiceById,
  updateOrderInvoiceStatus,
} from "../api/OrderInvoices";

// Styles
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "52px",
    },
  },
  papers: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      padding: "20px",
      width: "100%",
      height: "100%",
    },
  },
  TableCell: {
    paddingLeft: "16px",
    paddingRight: "8px",
    width: "15%",
  },
  tableImage: {
    paddingLeft: "16px",
    paddingRight: "-8px",
    with: "15%",
  },
  tableProduct: {
    paddingLeft: "16px",
    paddingRight: "-8px",
    width: "45%",
  },
}));

// Main component
const OrderDetails = ({ getUserCart }) => {
  const classes = useStyles();
  const { order_id } = useParams();
  const role = localStorage.getItem("role");
  const history = useHistory();
  const from_store = localStorage.getItem("from_store");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [customerUser, setCustomerUser] = useState({
    Details: {
      FirstName: "",
      LastName: "",
    },
    email: "",
  });
  const [order_invoice, setOrderInvoice] = React.useState({
    id: null,
    user: {
      Details: {
        FirstName: "",
        LastName: "",
      },
      email: "",
    },
    customer: {
      FirstName: "",
      LastName: "",
      billing_address: {
        Street: "",
        Street2: "",
        City: "",
        State: "",
        Zip: "",
        Country: "",
      },
      ConfirmationEmail: "",
      Phone: "",
      shipping_address: {
        Street: "",
        Street2: "",
        City: "",
        State: "",
        Zip: "",
      },
    },
    Status: "",
    Total: "",
    Note: "",
  });
  const [openSuccessCancelModal, setOpenSuccessCancelModal] =
    React.useState(false);
  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "error",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "error",
    });
  };

  // Get order_invoice at the first load
  React.useEffect(() => {
    getUserCart();
    // List order_invoice
    getOrderinvoice();
  }, []);

  /**
   * Puporse: Get order invoice
   */
  const getOrderinvoice = () => {
    setLoading(true);
    getOrderInvoiceById(order_id)
      .then((result) => {
        // Get order invoice data
        const orderInvoice =
          result && result.data && result.data.orderInvoice
            ? result.data.orderInvoice
            : [];
        // Validate sales order line items
        let lineItems =
          orderInvoice &&
          orderInvoice.sales_orders &&
          orderInvoice.sales_orders.length != 0 &&
          orderInvoice.sales_orders[0].LineItems
            ? orderInvoice.sales_orders[0].LineItems
            : null;
        if (lineItems && typeof lineItems == "string") {
          lineItems = JSON.parse(lineItems);
        }
        // Get pendding products
        let pendingProducts = [];
        lineItems &&
          lineItems.products &&
          lineItems.products.forEach((product) => {
            if (product && product.OpenQty && product.OpenQty > 0) {
              pendingProducts.push(product);
            }
          });
        orderInvoice.pendingProducts = pendingProducts;
        // Get order invoice tracking
        let orderInvoiceTracking = orderInvoice.orderInvoiceTrackings;
        orderInvoiceTracking.map((order) => {
          if (order && order.LineItems && typeof order.LineItems == "string") {
            order.LineItems = JSON.parse(order.LineItems);
          }
          return order;
        });
        orderInvoice.orderInvoiceTrackings = orderInvoiceTracking;
        // Get client
        const client = orderInvoice.client;
        // Validating that actumatica is active
        const isAcumaticaActive =
          client.acumatica && client.AcumaticaSetting?.Company ? true : false;
        orderInvoice.acumatica = isAcumaticaActive;
        setOrderInvoice(orderInvoice);
        // Get products
        const products =
          orderInvoice && orderInvoice.line_items
            ? orderInvoice.line_items
            : [];
        setProducts(products);
        // Get custome user
        const customUser =
          orderInvoice &&
          orderInvoice.staged_invoices &&
          Array.isArray(orderInvoice.staged_invoices) &&
          orderInvoice.staged_invoices.length > 0
            ? orderInvoice.staged_invoices[0].create_user
            : false;
        setCustomerUser(customUser);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  // View
  return (
    // Container
    <Container
      id="main_container"
      maxWidth="lg"
      style={{ marginTop: "85px" }}
      className={classes.mainContainer}
    >
      {/** Show current location */}
      <Grid item xs={12}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {role == "customer" ? (
            <Link
              color="textSecondary"
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            >
              User Profile
            </Link>
          ) : from_store == "UserProfile" ? (
            <Link
              color="textSecondary"
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            >
              User Profile
            </Link>
          ) : (
            <Link
              color="textSecondary"
              onClick={() => history.push("/app/orders")}
              style={{ cursor: "pointer" }}
            >
              Order History
            </Link>
          )}
          <Typography color="textSecondary">{`Order Details - ${order_id}`}</Typography>
        </Breadcrumbs>
      </Grid>
      {/* Order Invoice Details */}
      <Grid id="grid_" item xs={12} className={classes.papers}>
        <Paper id="paper_order_details" elevation={3}>
          {/* Title */}
          <Typography variant="h5" gutterBottom>
            <Box fontWeight="fontWeightBold">Order Invoice Details</Box>
          </Typography>

          <Grid container spacing={1}>
            {/**Order Details */}
            <Grid item xs={12} md={6}>
              <Typography>
                <Box fontWeight="fontWeightBold">Order Invoice Number</Box>
              </Typography>
              <Typography gutterBottom>
                {loading ? <Skeleton /> : order_invoice.id}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Create Date</Box>
              </Typography>
              <Typography gutterBottom>
                {loading ? (
                  <Skeleton />
                ) : (
                  moment(order_invoice.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                )}
              </Typography>
              {/*Customer */}
              <Typography>
                <Box fontWeight="fontWeightBold">Customer</Box>
              </Typography>
              <Typography gutterBottom>
                {loading ? (
                  <Skeleton />
                ) : customerUser ? (
                  customerUser.Details.FirstName +
                  " " +
                  customerUser.Details.LastName
                ) : (
                  "No customer user"
                )}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Customer Email</Box>
              </Typography>
              <Typography gutterBottom>
                {loading ? (
                  <Skeleton />
                ) : customerUser ? (
                  customerUser.email
                ) : (
                  "No customer user"
                )}
              </Typography>
              <br />
              {/*user */}
              <Typography>
                <Box fontWeight="fontWeightBold">Ordered by</Box>
              </Typography>
              <Typography gutterBottom>
                {loading ? (
                  <Skeleton />
                ) : // Check if order has existent user
                order_invoice.user &&
                  order_invoice.user.Details &&
                  order_invoice.user.Details.FirstName &&
                  order_invoice.user.Details.LastName ? (
                  `${order_invoice.user.Details.FirstName} ${order_invoice.user.Details.LastName}`
                ) : (
                  "No user"
                )}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Ordered by Email</Box>
              </Typography>
              <Typography gutterBottom>
                {loading ? (
                  <Skeleton />
                ) : order_invoice.user && order_invoice.user.email ? (
                  order_invoice.user.email
                ) : (
                  "No user"
                )}
              </Typography>
              <br />
              <Typography>
                <Box fontWeight="fontWeightBold">Shipping information</Box>
              </Typography>
              {loading ? (
                <Grid>
                  <Typography gutterBottom>
                    <Skeleton />
                  </Typography>
                  <Typography gutterBottom>
                    <Skeleton />
                  </Typography>
                  <Typography gutterBottom>
                    <Skeleton />
                  </Typography>
                  <Typography gutterBottom>
                    <Skeleton />
                  </Typography>
                  <Typography gutterBottom>
                    <Skeleton />
                  </Typography>
                </Grid>
              ) : order_invoice.customer ? (
                <Grid>
                  <Typography gutterBottom>
                    {order_invoice.customer.FirstName +
                      " " +
                      order_invoice.customer.LastName}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice.customer.shipping_address.Street +
                      " " +
                      order_invoice.customer.shipping_address.Street2}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice.customer.shipping_address.City +
                      ", " +
                      order_invoice.customer.shipping_address.State +
                      ", " +
                      order_invoice.customer.shipping_address.Zip}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice.customer.ConfirmationEmail}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice.customer.Phone}
                  </Typography>
                </Grid>
              ) : (
                ""
              )}

              <br />
              <Typography>
                <Box fontWeight="fontWeightBold">Status</Box>
              </Typography>
              {/**SubmittedForApproval */}
              <Typography gutterBottom>
                {loading ? (
                  <Skeleton />
                ) : order_invoice.Status == "SubmittedForApproval" ||
                  order_invoice.Status == "BackOrder" ? (
                  order_invoice.Status.replace(
                    /([a-z])([A-Z])/g,
                    "$1 $2"
                  ).trim()
                ) : (
                  order_invoice.Status +
                  " " +
                  moment(order_invoice.updated_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                )}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Total</Box>
              </Typography>
              <Typography gutterBottom>
                ${loading ? <Skeleton /> : order_invoice.Total}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Payments</Box>
              </Typography>
              {loading ? (
                <Skeleton />
              ) : order_invoice.order_invoice_payments ? (
                order_invoice.order_invoice_payments.map((payment, index) => (
                  <Typography key={index}>
                    {payment.Type == "Points" ? "Points: $" : "Credit Card: $"}
                    {payment.Amount}
                  </Typography>
                ))
              ) : null}
            </Grid>
            {/**Shipment Details */}
            <Grid
              item
              xs={12}
              md={6}
              style={{
                height: "700px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {/**Shipping label */}
              <Typography variant="h5">
                <Box fontWeight="fontWeightBold">Shipping</Box>
              </Typography>
              {/**Product Shipped label */}
              <Typography style={{ marginTop: "27px" }}>
                <Box fontWeight="fontWeightBold">Products Shipped</Box>
              </Typography>
              <Divider style={{ width: "100%", backgroundColor: "#010000" }} />
              {/**Product Shipped details */}
              <Grid container spacing={1} style={{ marginTop: "5px" }}>
                <Grid item xs={6} md={6}>
                  <Typography>
                    <Box fontWeight="fontWeightBold">Product</Box>
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Typography>
                    <Box fontWeight="fontWeightBold">Date</Box>
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Typography>
                    <Box fontWeight="fontWeightBold">Tracking Number</Box>
                  </Typography>
                </Grid>
                {!loading &&
                order_invoice &&
                order_invoice.orderInvoiceTrackings &&
                order_invoice.orderInvoiceTrackings.length != 0 ? (
                  order_invoice.orderInvoiceTrackings.map((shipment) => {
                    return (
                      <>
                        <Grid item xs={6} md={6}>
                          {shipment &&
                            shipment.LineItems &&
                            shipment.LineItems.map((item) => {
                              return (
                                <>
                                  <Typography gutterBottom display="inline">
                                    {`${item.ShippedQty} ${item.Description}`}
                                  </Typography>
                                  <br />
                                </>
                              );
                            })}
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Typography gutterBottom>
                            {moment(shipment.updated_at).format("MMM, DD")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Typography gutterBottom>
                            <a href={shipment.TrackingUrl}>
                              {shipment.TrackingNumber}
                            </a>
                          </Typography>
                        </Grid>
                      </>
                    );
                  })
                ) : !loading ? (
                  <Grid item xs={12} md={12}>
                    <Typography gutterBottom>
                      No shipments to display
                    </Typography>
                  </Grid>
                ) : (
                  [0, 1, 2].map(() => {
                    return (
                      <>
                        <Grid item xs={6} md={6}>
                          <Typography gutterBottom display="inline">
                            <Skeleton />
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Typography gutterBottom>
                            <Skeleton />
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Typography gutterBottom>
                            <Skeleton />
                          </Typography>
                        </Grid>
                      </>
                    );
                  })
                )}
              </Grid>
              {/**Pending products details */}
              <Typography style={{ marginTop: "27px" }}>
                <Box fontWeight="fontWeightBold">Pending</Box>
              </Typography>
              <Divider style={{ width: "100%", backgroundColor: "#010000" }} />
              <Grid container spacing={1} style={{ marginTop: "5px" }}>
                <Grid item xs={6} md={6}>
                  <Typography>
                    <Box fontWeight="fontWeightBold">Product</Box>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography>
                    <Box fontWeight="fontWeightBold">Info</Box>
                  </Typography>
                </Grid>
                {!loading &&
                order_invoice &&
                order_invoice.pendingProducts &&
                order_invoice.pendingProducts.length != 0 ? (
                  order_invoice.pendingProducts.map((product) => {
                    if (product && product.OpenQty > 0) {
                      return (
                        <>
                          <Grid item xs={6} md={6}>
                            <Typography gutterBottom display="inline">
                              {`${product.OpenQty} ${product.Description}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Typography gutterBottom>
                              To be shipped soon
                            </Typography>
                          </Grid>
                        </>
                      );
                    }
                  })
                ) : !loading ? (
                  <Grid item xs={12} md={12}>
                    <Typography gutterBottom>
                      No pending products to display
                    </Typography>
                  </Grid>
                ) : (
                  [0, 1, 2].map(() => {
                    return (
                      <>
                        <Grid item xs={6} md={6}>
                          <Typography gutterBottom display="inline">
                            <Skeleton />
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography gutterBottom>
                            <Skeleton />
                          </Typography>
                        </Grid>
                      </>
                    );
                  })
                )}
              </Grid>
              <Typography gutterBottom style={{ marginTop: "27px" }}>
                Updates will be provided via email
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={{ marginTop: "10px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="Image"
                      align="left"
                      padding="none"
                      className={classes.tableImage}
                    >
                      <b>Image</b>
                    </TableCell>
                    <TableCell
                      key="Product"
                      align="left"
                      padding="none"
                      className={classes.tableProduct}
                    >
                      <b>Product</b>
                    </TableCell>
                    <TableCell
                      key="Price"
                      align="left"
                      padding="none"
                      className={classes.TableCell}
                    >
                      <b>Price</b>
                    </TableCell>
                    <TableCell
                      key="Quantity"
                      align="left"
                      padding="none"
                      className={classes.TableCell}
                    >
                      <b>Quantity</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? [1, 2].map((index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={classes.tableImage}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <center>
                                <Skeleton
                                  variant="rect"
                                  height={50}
                                  style={{ marginBottom: "5px" }}
                                />
                              </center>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableProduct}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.TableCell}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.TableCell}
                            >
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : products.map((product, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        // Get product id
                        const productId =
                          product && product.product && product.product.id
                            ? product.product.id
                            : product &&
                              product.LineItemJson &&
                              product.LineItemJson.ProductId
                            ? product.LineItemJson.ProductId
                            : null;
                        return (
                          <TableRow tabIndex={-1} key={productId}>
                            <TableCell
                              className={classes.tableImage}
                              component="th"
                              id={labelId}
                              scope={"row"}
                              padding="none"
                            >
                              <center>
                                <img
                                  src={
                                    product &&
                                    product.product &&
                                    product.product.Image &&
                                    Array.isArray(product.product.Image) &&
                                    product.product.Image.length > 0
                                      ? product.product.Image[0].url
                                      : "../../../img/avid/avid-io_500x532.png"
                                  }
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    maxHeight: "125px",
                                  }}
                                />
                              </center>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableProduct}
                            >
                              {product.LineItemJson.ProductName}
                              {product.LineItemJson &&
                              product.LineItemJson.Attributes
                                ? product.LineItemJson.Attributes.map(
                                    (attr, index) =>
                                      attr.value && attr.value !== "" ? (
                                        <Typography key={index}>
                                          {attr.Name}: {attr.value}
                                        </Typography>
                                      ) : (
                                        ""
                                      )
                                  )
                                : null}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {`$${Number(
                                product.LineItemJson.ProductPrice
                              ).toFixed(2)}`}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {product.Quantity}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            {/** Button to cancel order */}
            {role && role == "superadmin" && (
              <Grid xs={12} style={{ float: "right", marginTop: "20px" }}>
                <AlertDialog
                  openButtonText={"CANCEL ORDER"}
                  dialogTitle={"Cancel Order"}
                  dialogContentText={
                    "After the cancellation is confirmed, the order status will be updated to\n" +
                    '"Canceled" in our system, and you will receive a confirmation email. \n' +
                    "Do you want to continue?\n"
                  }
                  closeButtonText="Cancel"
                  confirmButtonText="Continue"
                  confirmButtonClick={() => {
                    // Making request to cancel order
                    updateOrderInvoiceStatus(order_invoice.id, "Canceled").then(
                      (result) => {
                        if (result.data && result.data.updateOrderInvoice) {
                          setOpenSuccessCancelModal(true);
                          getOrderinvoice();
                          setError({
                            open: true,
                            message: `The order was successfully cancelled`,
                            severity: "success",
                          });
                        } else {
                          let errorMessage =
                            result.errors &&
                            result.errors &&
                            Array.isArray(result.errors) &&
                            result.errors.length !== 0
                              ? result.errors[0].message
                              : "Something happened while trying to cancel the order, try again or contact an administrator.";
                          setError({
                            open: true,
                            message: errorMessage,
                            severity: "error",
                          });
                        }
                      }
                    );
                  }}
                  openButtonProps={{
                    disabled:
                      order_invoice.Status !== "Created" ||
                      !order_invoice.acumatica,
                  }}
                />
              </Grid>
            )}
            {/** Show possible errors or success*/}
            <SnackbarMessage
              open={error.open}
              severity={error.severity}
              message={error.message}
              handleClose={handleCloseSnackbar}
            />
            {/** Show Modal possible errors or success*/}
            <SuccessCancelOrderModal
              open={openSuccessCancelModal}
              handleClose={() =>
                setOpenSuccessCancelModal(!openSuccessCancelModal)
              }
              orderId={order_invoice.id}
            />
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
};

OrderDetails.propTypes = {
  getUserCart: PropTypes.func,
};

export default OrderDetails;
