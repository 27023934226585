// Libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import moment from "moment";

import Skeleton from "@material-ui/lab/Skeleton";

// Custom components

// API
import { getOrderInvoiceById } from "../../api/OrderInvoices";

// Styles
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      // paddingLeft: "190px",
    },
  },
  papers: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      padding: "20px",
      width: "100%",
      height: "100%",
    },
  },
  TableCell: {
    paddingLeft: "16px",
    paddingRight: "8px",
    width: "15%",
  },
  tableImage: {
    paddingLeft: "16px",
    paddingRight: "-8px",
    with: "15%",
  },
  tableProduct: {
    paddingLeft: "16px",
    paddingRight: "-8px",
    width: "45%",
  },
}));

// Main component
const OrderDetailsModal = ({ open, orderId, handleClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [customerUser, setCustomerUser] = useState({
    Details: {
      FirstName: "",
      LastName: "",
    },
    email: "",
  });
  const [order_invoice, setOrderInvoice] = React.useState({
    id: null,
    customer: {
      FirstName: "",
      LastName: "",
      billing_address: {
        Street: "",
        Street2: "",
        City: "",
        State: "",
        Zip: "",
        Country: "",
      },
      ConfirmationEmail: "",
      Phone: "",
    },
    Status: "",
    Total: "",
    Note: "",
  });

  // Get order_invoice at the first load
  React.useEffect(() => {
    // List order_invoice
    if (orderId) {
      getOrderInvoiceById(orderId)
        .then((result) => {
          const orderInvoice =
            result && result.data && result.data.orderInvoice
              ? result.data.orderInvoice
              : [];
          setOrderInvoice(orderInvoice);
          const products =
            orderInvoice && orderInvoice.line_items
              ? orderInvoice.line_items
              : [];
          setProducts(products);
          const customUser =
            orderInvoice &&
            orderInvoice.staged_invoices &&
            Array.isArray(orderInvoice.staged_invoices) &&
            orderInvoice.staged_invoices.length > 0
              ? orderInvoice.staged_invoices[0].create_user
              : false;
          setCustomerUser(customUser);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [orderId]);

  // View
  return (
    // Container
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        {/* Order Invoice Details */}
        <Grid id="grid_" item xs={12}>
          {/* Title */}
          <Typography variant="h5" gutterBottom>
            <Box fontWeight="fontWeightBold">Order Invoice Details</Box>
          </Typography>
          {loading ? (
            <>
              <Typography>
                <Box fontWeight="fontWeightBold">Order Invoice Number</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Create Date</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              {/*Customer */}
              <Typography>
                <Box fontWeight="fontWeightBold">Customer</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Customer Email</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <br />
              {/*user */}
              <Typography>
                <Box fontWeight="fontWeightBold">Ordered by</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Ordered by Email</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <br />
              <Typography>
                <Box fontWeight="fontWeightBold">Shipping information</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <br />
              <Typography>
                <Box fontWeight="fontWeightBold">Status</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Total</Box>
              </Typography>
              <Typography gutterBottom>
                <Skeleton />
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Payments</Box>
              </Typography>
              <Typography>
                <Skeleton />
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                <Box fontWeight="fontWeightBold">Order Invoice Number</Box>
              </Typography>
              <Typography gutterBottom>{order_invoice.id}</Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Create Date</Box>
              </Typography>
              <Typography gutterBottom>
                {moment(order_invoice.created_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </Typography>
              {/*Customer */}
              <Typography>
                <Box fontWeight="fontWeightBold">Customer</Box>
              </Typography>
              <Typography gutterBottom>
                {customerUser
                  ? customerUser.Details.FirstName +
                    " " +
                    customerUser.Details.LastName
                  : "No customer user"}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Customer Email</Box>
              </Typography>
              <Typography gutterBottom>
                {customerUser ? customerUser.email : "No customer user"}
              </Typography>
              <br />
              {/*user */}
              <Typography>
                <Box fontWeight="fontWeightBold">Ordered by</Box>
              </Typography>
              <Typography gutterBottom>
                {order_invoice &&
                order_invoice.user &&
                order_invoice.user.Details &&
                order_invoice.user.Details.FirstName
                  ? order_invoice.user.Details.FirstName
                  : "No Fisrt name" + " " + order_invoice &&
                    order_invoice.user &&
                    order_invoice.user.Details &&
                    order_invoice.user.Details.LastName
                  ? order_invoice.user.Details.LastName
                  : "No Last name"}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Ordered by Email</Box>
              </Typography>
              <Typography gutterBottom>
                {order_invoice && order_invoice.user && order_invoice.user.email
                  ? order_invoice.user.email
                  : "No email"}
              </Typography>
              <br />
              <Typography>
                <Box fontWeight="fontWeightBold">Shipping information</Box>
              </Typography>
              {order_invoice.customer ? (
                <Grid>
                  <Typography gutterBottom>
                    {order_invoice.customer.FirstName +
                      " " +
                      order_invoice.customer.LastName}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice &&
                    order_invoice.customer &&
                    order_invoice.customer.shipping_address &&
                    order_invoice.customer.shipping_address.Street
                      ? order_invoice.customer.shipping_address.Street
                      : "No Street" + " " + order_invoice &&
                        order_invoice.customer &&
                        order_invoice.customer.shipping_address &&
                        order_invoice.customer.shipping_address.Street2
                      ? order_invoice.customer.shipping_address.Street2
                      : ""}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice &&
                    order_invoice.customer &&
                    order_invoice.customer.shipping_address &&
                    order_invoice.customer.shipping_address.City
                      ? order_invoice.customer.shipping_address.City
                      : "No City" + ", " + order_invoice &&
                        order_invoice.customer &&
                        order_invoice.customer.shipping_address &&
                        order_invoice.customer.shipping_address.State
                      ? order_invoice.customer.shipping_address.State
                      : "No State" + ", " + order_invoice &&
                        order_invoice.customer &&
                        order_invoice.customer.shipping_address &&
                        order_invoice.customer.shipping_address.Zip
                      ? order_invoice.customer.shipping_address.Zip
                      : "No Zip"}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice.customer.ConfirmationEmail}
                  </Typography>
                  <Typography gutterBottom>
                    {order_invoice.customer.Phone}
                  </Typography>
                </Grid>
              ) : (
                ""
              )}

              <br />
              <Typography>
                <Box fontWeight="fontWeightBold">Status</Box>
              </Typography>
              <Typography gutterBottom>
                {order_invoice.Status == "SubmittedForApproval"
                  ? order_invoice.Status.replace(
                      /([a-z])([A-Z])/g,
                      "$1 $2"
                    ).trim()
                  : order_invoice.Status +
                    " " +
                    moment(order_invoice.updated_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Total</Box>
              </Typography>
              <Typography gutterBottom>${order_invoice.Total}</Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">Payments</Box>
              </Typography>
              {order_invoice.order_invoice_payments
                ? order_invoice.order_invoice_payments.map((payment, index) => (
                    <Typography key={index}>
                      {payment.Type == "Points"
                        ? "Points: $"
                        : "Credit Card: $"}
                      {payment.Amount}
                    </Typography>
                  ))
                : null}
            </>
          )}
          <Grid item xs={12}>
            <TableContainer style={{ marginTop: "10px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="Image"
                      align="left"
                      padding="none"
                      className={classes.tableImage}
                    >
                      <b>Image</b>
                    </TableCell>
                    <TableCell
                      key="Product"
                      align="left"
                      padding="none"
                      className={classes.tableProduct}
                    >
                      <b>Product</b>
                    </TableCell>
                    <TableCell
                      key="Price"
                      align="left"
                      padding="none"
                      className={classes.TableCell}
                    >
                      <b>Price</b>
                    </TableCell>
                    <TableCell
                      key="Quantity"
                      align="left"
                      padding="none"
                      className={classes.TableCell}
                    >
                      <b>Quantity</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? [1, 2].map((index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={classes.tableImage}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <center>
                                <Skeleton
                                  variant="rect"
                                  height={50}
                                  style={{ marginBottom: "5px" }}
                                />
                              </center>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableProduct}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.TableCell}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.TableCell}
                            >
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : products.map((product, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        // Get product id
                        const productId =
                          product && product.product && product.product.id
                            ? product.product.id
                            : product &&
                              product.LineItemJson &&
                              product.LineItemJson.ProductId
                            ? product.LineItemJson.ProductId
                            : null;
                        return (
                          <TableRow tabIndex={-1} key={productId}>
                            <TableCell
                              className={classes.tableImage}
                              component="th"
                              id={labelId}
                              scope={"row"}
                              padding="none"
                            >
                              <center>
                                <img
                                  src={
                                    product &&
                                    product.product &&
                                    product.product.Image &&
                                    Array.isArray(product.product.Image) &&
                                    product.product.Image.length > 0
                                      ? product.product.Image[0].url
                                      : "../../../img/avid/avid-io_500x532.png"
                                  }
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    maxHeight: "125px",
                                  }}
                                />
                              </center>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableProduct}
                            >
                              {product.LineItemJson.ProductName}
                              {product.LineItemJson &&
                              product.LineItemJson.Attributes
                                ? product.LineItemJson.Attributes.map(
                                    (attr, index) =>
                                      attr.value && attr.value !== "" ? (
                                        <Typography key={index}>
                                          {attr.Name}: {attr.value}
                                        </Typography>
                                      ) : (
                                        ""
                                      )
                                  )
                                : null}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {`$${Number(
                                product.LineItemJson.ProductPrice
                              ).toFixed(2)}`}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {product.LineItemJson.Quantity}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            //Close modal
            handleClose();
            //Clear modal
            setProducts([]);
            setLoading(true);
          }}
          variant="contained"
          style={{ marginRight: "20px" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrderDetailsModal.propTypes = {
  orderId: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default OrderDetailsModal;
