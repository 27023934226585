// Libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import {
  Divider,
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Box,
  Link,
  IconButton,
  Hidden,
  Grid,
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import EditIcon from "@material-ui/icons/Edit";
import SnackbarMessage from "../SnackbarMessage";
// Custom components
import ProductDialog from "../store/ProductDialog";
import AlertDialog from "../AlertDialog";
import { Alert, AlertTitle } from "@material-ui/lab";

// API
import { deleteStagedLineItem } from "../../api/StagedLineItems";

// Styles needed for responsiveness
const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  no_products: {
    marginTop: 15,
    fontSize: 18,
  },
}));

// Main component
const ShoppingCart = ({ staged_invoice, getUserCart }) => {
  const classes = useStyles();
  const history = useHistory();

  // Set default product values
  let initial_product_schema = {
    id: null,
    Description: "",
    Image: [],
    Name: "",
    Price: null,
    Published: true,
    Sku: "",
    Summary: "",
    division_categories: [],
    divisions: [],
    Quantity: 0,
    product_attributes: [],
  };
  const [initial_product, setInitialProduct] = React.useState(
    initial_product_schema
  );
  const [openProductModal, setOpenProductModal] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(initial_product);
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  const [user, setUser] = useState(false);
  const client_id = localStorage.getItem("client_id");
  // Open/close product dialog
  const triggerProductModal = (product) => {
    if (product.id) {
      setSelectedProduct(product);
    } else {
      if (product && product.sucess == "Add_sucess") {
        //Show SnackbarMessage
        setError({
          open: true,
          message: "Updated " + product.ProductName + " in your shopping bag.",
          severity: "success",
        });
      }
      setSelectedProduct(initial_product);
    }
    setOpenProductModal(!openProductModal);
  };
  //useEffect to change initial values if staged_invoice changes
  useEffect(() => {
    //check if the selected user is not the logged user
    let store_user = JSON.parse(localStorage.getItem("store_user"));
    if (store_user && store_user.id) {
      setUser(store_user);
    }
    let initial_attributes = [];
    staged_invoice
      ? staged_invoice.staged_line_items.forEach((sli) =>
          sli.LineItemJson.Attributes.forEach((attr) => {
            if (
              !initial_attributes.some(
                (initial_attr) => initial_attr.Name == attr.Name
              )
            ) {
              initial_attributes.push(attr);
            }
          })
        )
      : null;
    setInitialProduct({
      ...initial_product,
      product_attributes: initial_attributes,
    });
    setSelectedProduct({
      ...initial_product,
      product_attributes: initial_attributes,
    });
  }, [staged_invoice]);

  const handleCloseMessage = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };
  // View
  return (
    <Paper id="paper_shopping_cart" elevation={3}>
      {/* Title */}
      <Typography variant="h5" gutterBottom>
        <Box fontWeight="fontWeightBold">
          <ShoppingCartIcon /> Shopping Cart
        </Box>
      </Typography>
      {user && user.id ? (
        <Alert severity="info">
          <AlertTitle>{`${user.search_label}'s Store`}</AlertTitle>
          {`Orders will be placed in ${user.search_label}'s account`}
        </Alert>
      ) : (
        ""
      )}
      <Divider />
      {/* ########## Cart Products List ########## */}
      {staged_invoice && staged_invoice.staged_line_items.length > 0 ? (
        <React.Fragment>
          {/* Middle and upper screens view */}
          <Hidden smDown>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                {/* Headers */}
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Typography>
                        <Box fontWeight="fontWeightBold">Product</Box>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        <Box fontWeight="fontWeightBold">Actions</Box>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        <Box fontWeight="fontWeightBold">Points</Box>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        <Box fontWeight="fontWeightBold">Price</Box>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        <Box fontWeight="fontWeightBold">Cost</Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* Product list & total */}
                <TableBody>
                  {/* Products */}
                  {staged_invoice.staged_line_items.map((sli, index) => (
                    <TableRow key={index}>
                      {/* Image */}
                      <TableCell>
                        <img
                          src={
                            sli.product.Image.length > 0
                              ? sli.product.Image[0].url
                              : "../../../img/avid/avid-io_500x532.png"
                          }
                          style={{
                            maxWidth: "150px",
                            height: "auto",
                            maxHeight: "150px",
                          }}
                        />
                      </TableCell>
                      {/* Product Info */}
                      <TableCell align="right">
                        <Typography>
                          <Box fontWeight="fontWeightBold">
                            {sli.product.Name}
                          </Box>
                        </Typography>
                        <Typography>Quantity: {sli.Quantity}</Typography>
                        {sli.LineItemJson && sli.LineItemJson.Attributes
                          ? sli.LineItemJson.Attributes.map((attr, index) =>
                              attr.value && attr.value != "" ? (
                                <Typography key={index}>
                                  {attr.Name}: {attr.value}
                                </Typography>
                              ) : (
                                ""
                              )
                            )
                          : null}
                      </TableCell>
                      {/* Actions */}
                      <TableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="edit_item"
                          aria-haspopup="true"
                          color="inherit"
                          onClick={() =>
                            triggerProductModal({
                              ...sli.product,
                              Quantity: sli.Quantity,
                              sli_attributes: sli.LineItemJson.Attributes,
                              staged_line_item_id: sli.id,
                            })
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <AlertDialog
                          openButton="icon"
                          openButtonColor="inherit"
                          dialogTitle="Delete Product From Cart"
                          dialogContentText="This product will be removed from the cart. Do you want to continue?"
                          confirmButtonClick={() =>
                            deleteStagedLineItem(sli.id).then(() => {
                              //Show SnackbarMessage
                              setError({
                                open: true,
                                message:
                                  "Deleted " +
                                  sli.product.Name +
                                  " from your shopping bag.",
                                severity: "success",
                              });
                              getUserCart(true);
                            })
                          }
                        />
                      </TableCell>
                      {/* Points */}
                      <TableCell align="right">
                        <Typography>{sli.division_category.Name}</Typography>
                        <Typography>
                          Available: {sli.AvailablePoints}
                        </Typography>
                      </TableCell>
                      {/* Price */}
                      <TableCell align="right">
                        <Typography>Price: ${sli.Price}</Typography>
                        <Typography>Point Used: {sli.PointsUsed}</Typography>
                      </TableCell>
                      {/* Cost */}
                      <TableCell align="right">
                        <Typography>${sli.Cost}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* Total */}
                  <TableRow>
                    {/* Empty space */}
                    <TableCell colSpan={4}></TableCell>
                    {/* Remaining */}
                    <TableCell align="right">
                      <Typography variant="h6">
                        <Box fontWeight="fontWeightBold">Remaining:</Box>
                      </Typography>
                      {staged_invoice
                        ? staged_invoice.remaining.map((division, index) => (
                            <Typography key={index}>
                              {division.DivisionCategoryName}:{" "}
                              {division.RemainigPoints}
                            </Typography>
                          ))
                        : null}
                    </TableCell>
                    {/* Total price */}
                    <TableCell align="right">
                      {staged_invoice &&
                      staged_invoice.point_cost_adjustment &&
                      staged_invoice.total_price > 0 ? (
                        <Typography align="center">
                          <Box fontWeight="fontWeightBold" component="span">
                            Point cost adjustment:{" "}
                          </Box>
                          ${staged_invoice.point_cost_adjustment}
                        </Typography>
                      ) : null}
                      <Typography variant="h6">
                        <Box fontWeight="fontWeightBold" component="span">
                          Total Price:{" "}
                        </Box>
                        ${staged_invoice ? staged_invoice.total_price : "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
          {/* Small and lower screens view */}
          <Hidden mdUp>
            {staged_invoice.staged_line_items.map((sli, index) => (
              <React.Fragment key={index}>
                <Grid container alignItems="center" justify="flex-end">
                  {/* Image */}
                  <Grid xs={6}>
                    <img
                      src={
                        sli.product.Image.length > 0
                          ? sli.product.Image[0].url
                          : "../../../img/avid/avid-io_500x532.png"
                      }
                      style={{
                        maxWidth: "150px",
                        height: "auto",
                        maxHeight: "150px",
                      }}
                    />
                  </Grid>
                  {/* Product Info */}
                  <Grid xs={6}>
                    <Typography>
                      <Box fontWeight="fontWeightBold">{sli.product.Name}</Box>
                    </Typography>
                    <Typography>Quantity: {sli.Quantity}</Typography>
                    {sli.LineItemJson && sli.LineItemJson.Attributes
                      ? sli.LineItemJson.Attributes.map((attr, index) => (
                          <Typography key={index}>
                            {attr.Name}: {attr.value}
                          </Typography>
                        ))
                      : null}
                  </Grid>
                  {/* Points */}
                  <Grid xs={6}>
                    <Typography>{sli.division_category.Name}</Typography>
                    <Typography>Available: {sli.AvailablePoints}</Typography>
                  </Grid>
                  {/* Price */}
                  <Grid xs={6}>
                    <Typography>Price: ${sli.Price}</Typography>
                    <Typography>Point Used: {sli.PointsUsed}</Typography>
                  </Grid>
                  {/* Actions */}
                  <Grid xs={6}>
                    <IconButton
                      edge="end"
                      aria-label="edit_item"
                      aria-haspopup="true"
                      color="inherit"
                      onClick={() =>
                        triggerProductModal({
                          ...sli.product,
                          Quantity: sli.Quantity,
                          sli_attributes: sli.LineItemJson.Attributes,
                          staged_line_item_id: sli.id,
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <AlertDialog
                      openButton="icon"
                      openButtonColor="inherit"
                      dialogTitle="Delete Product From Cart"
                      dialogContentText="This product will be removed from the cart. Do you want to continue?"
                      confirmButtonClick={() =>
                        deleteStagedLineItem(sli.id).then(() =>
                          getUserCart(true)
                        )
                      }
                    />
                  </Grid>
                  {/* Cost */}
                  <Grid xs={6}>
                    <Typography>
                      <Box fontWeight="fontWeightBold" fontSize={20}>
                        ${sli.Cost}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            ))}
            {/* Total */}
            <Grid container alignItems="center" style={{ marginTop: "15px" }}>
              {/* Remaining */}
              <Grid xs={6}>
                <Typography variant="h6" align="center">
                  <Box fontWeight="fontWeightBold">Remaining:</Box>
                </Typography>
                {staged_invoice
                  ? staged_invoice.remaining.map((division, index) => (
                      <Typography key={index} align="center">
                        {division.DivisionCategoryName}:{" "}
                        {division.RemainigPoints}
                      </Typography>
                    ))
                  : null}
              </Grid>
              {/* Total price */}
              <Grid xs={6}>
                {staged_invoice &&
                staged_invoice.point_cost_adjustment &&
                staged_invoice.total_price > 0 ? (
                  <Typography align="center">
                    <Box fontWeight="fontWeightBold" component="span">
                      Point cost adjustment:{" "}
                    </Box>
                    ${staged_invoice.point_cost_adjustment}
                  </Typography>
                ) : null}
                <Typography variant="h6" align="center">
                  <Box fontWeight="fontWeightBold" component="span">
                    Total Price:{" "}
                  </Box>
                  ${staged_invoice ? staged_invoice.total_price : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </React.Fragment>
      ) : (
        /* No Products */
        <Typography className={classes.no_products}>
          * No items adding to your cart! Click{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              history.push(
                (localStorage.getItem("store_user") ||
                  localStorage.getItem("client_id")) &&
                  localStorage.getItem("role") != "customer"
                  ? `/app/client/${localStorage.getItem(
                      "client_id"
                    )}/store/products`
                  : "/store/products"
              );
            }}
          >
            here
          </Link>{" "}
          to start shopping!
        </Typography>
      )}
      <ProductDialog
        open={openProductModal}
        product={selectedProduct}
        handleClose={triggerProductModal}
        currentDivision={null}
        getUserCart={getUserCart}
        updating={true}
        client_id={client_id}
      />
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseMessage}
      />
    </Paper>
  );
};

ShoppingCart.propTypes = {
  staged_invoice: PropTypes.any,
  getUserCart: PropTypes.func,
};

export default ShoppingCart;
